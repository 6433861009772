import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import MainCard from '../../../../ui-component/cards/MainCard';
import { editCustomerRequest, getCustomerByIDRequest } from '../../../../utils/api';
import appNotify from '../../../../utils/libs/appNotify';
import CustomerForm from '../CustomerForm';
import PropTypes from 'prop-types';
import { formatPhoneNumber } from '../../../../utils/libs';
import { useTranslation } from 'react-i18next';

function CustomerDetails({ customerID, onClose }) {
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(null);

  const { t } = useTranslation();

  function editCustomer(values) {
    setLoading(true);
    console.log('Values: ', values);
    editCustomerRequest(customerID, values)
      .then((res) => {
        appNotify.success('Successfully edited Customer details');
        setLoading(false);
        onClose(true);
      })
      .catch((err) => {
        console.log('Err: ', err);
        appNotify.error('Failed to edit customer');
        setLoading(false);
      });
  }

  function getCustomerDetails() {
    getCustomerByIDRequest(customerID)
      .then((res) => {
        console.log('Res: ', res);
        setInitialValues({
          customer: {
            full_name: res.customer.full_name,
            email: res.customer.email,
            address: res.customer.address,
            id_number: res.customer.id_number,
            company_name: res.customer.company_name,
            other_names: res.customer.other_names
          },
          cellphones: res.customer.cellphones
        });
      })
      .catch((err) => {
        console.log('Err: ', err);
        appNotify.error('Failed to load customer details');
      });
  }

  useEffect(() => {
    getCustomerDetails();
  }, []);

  return (
    <MainCard title={t('customers.edit')} content={false} border={false} boxShadow>
      <Box sx={{ p: { xs: 2, sm: 3, md: 4, xl: 5 } }}>
        {initialValues && <CustomerForm initialValues={initialValues} loading={loading} onSubmit={(values) => editCustomer(values)} />}
      </Box>
    </MainCard>
  );
}

CustomerDetails.propTypes = {
  customerID: PropTypes.any,
  onClose: PropTypes.func
};

export default CustomerDetails;
