import React, { forwardRef, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { Grid, MenuItem, Typography, Select, Slide, DialogContent, DialogTitle } from '@mui/material';
import { currencyFormatter } from '../utils/libs';
import MainCard from './cards/MainCard';
import { addCurrencyRateRequest, getCurrencyRateRequest } from '../utils/api';
import { useDispatch, useSelector } from 'react-redux';
import { setRate } from '../store/reducers/app';
import { LoadingButton } from '@mui/lab';
import AnimateButton from './extended/AnimateButton';
import Dialog from '@mui/material/Dialog';
import ExchangeRateForm from './ExchangeRateForm';
import appNotify from '../utils/libs/appNotify';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import EditContainerStatusModal from '../pages/containers/EditContainerStatusModal';
import PermissionProvider from '../pages/users-roles-manager/components/PermissionHandler';
import { PERMISSIONS } from '../utils/libs/permissions';

export function NumberFormatter({ name, onChange, maxFloatValue, prefix, suffix = '', ref, ...other }) {
  // const { onChange, maxFloatValue, prefix, suffix, ref, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      valueIsNumericString
      prefix={`${prefix} `}
      suffix={` ${suffix}`}
      isAllowed={(values) => {
        const { floatValue } = values;
        return floatValue <= maxFloatValue; // Set maximum value here
      }}
    />
  );
}

NumberFormatter.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  maxFloatValue: PropTypes.number,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  ref: PropTypes.any
};

export const NumericCustom = forwardRef((props, ref) => <NumberFormatter {...props} maxFloatValue={100000} prefix="USD" />);
export const TZSNumericCustom = forwardRef((props, ref) => <NumberFormatter {...props} maxFloatValue={100000} prefix="TZS" />);

export function DocumentTitle({ title, children }) {
  useEffect(() => {
    document.title = title;
    return () => {
      document.title = 'GNM CARGO | CMTS ';
    };
  }, [title]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

export default function AmountView({ amount, title = 'AMOUNT', onChange = () => {} }) {
  const [currency, setCurrency] = useState('USD');
  const app = useSelector((state) => state.app);

  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item xs={6}>
        <Grid container alignItems="center" alignContent="center" justifyContent="space-around">
          <Grid item xs={3}>
            <Typography variant="h5">{title}</Typography>
          </Grid>
          <Grid item xs={9}>
            <MainCard style={{ width: 300 }}>
              <Grid container alignItems="center" justifyContent="center">
                <Grid xs={4}>
                  <Select
                    labelId="select-currency"
                    id="demo-simple-select-autowidth"
                    value={currency}
                    onChange={(e) => {
                      setCurrency(e.target.value);
                      if (e.target.value === 'TZS') {
                        onChange('TZS', amount * app.rate);
                      } else {
                        onChange('USD', amount);
                      }
                    }}
                    autoWidth
                  >
                    <MenuItem value="USD">USD</MenuItem>
                    <MenuItem value="TZS">TZS</MenuItem>
                  </Select>
                </Grid>
                <Grid xs={8}>
                  <Typography variant="h3">
                    {currency === 'USD' ? currencyFormatter(amount, 'USD') : currencyFormatter(amount * app.rate, 'TZS')}
                  </Typography>
                </Grid>
              </Grid>
            </MainCard>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export function CurrencyRate({ changeable = true }) {
  const app = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  function handleClose() {
    setOpen(false);
  }
  function changeCurrencyRate(values) {
    setLoading(true);
    addCurrencyRateRequest(values)
      .then((res) => {
        console.log('Res: ', res);
        setLoading(true);
        appNotify.success('Exchange rate edited successfully');
        dispatch(setRate(res.rate));
        setOpen(false);
        setLoading(false);
      })
      .catch((err) => {
        console.log('Err: ', err);
        setLoading(false);
        appNotify.error(t('common.tryAgain'));
      });
  }
  return (
    <div>
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <MainCard style={{ padding: 0 }}>Rate: $1 = {currencyFormatter(app.rate, 'TZS')}</MainCard>
        </Grid>
        {changeable && (
          <PermissionProvider permission={PERMISSIONS.CHANGE_RATE} showUnAuthorizedPage={false}>
            <Grid item>
              <AnimateButton>
                <LoadingButton disableElevations fullWidth onClick={() => setOpen(true)} size="small" variant="outlined">
                  Change
                </LoadingButton>
              </AnimateButton>
            </Grid>
          </PermissionProvider>
        )}
      </Grid>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
        <DialogTitle style={{ fontWeight: 'bold', fontSize: 20 }}>Editing Currency Rate</DialogTitle>
        <DialogContent>
          <ExchangeRateForm loading={loading} onSubmit={(values) => changeCurrencyRate(values)} />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export function CurrencyRateProvider() {
  const dispatch = useDispatch();

  function getCurrencyRate() {
    getCurrencyRateRequest()
      .then((res) => {
        // console.log('currency: ', res);
        dispatch(setRate(parseFloat(res.rate)));
      })
      .catch((err) => {
        console.log('Err: ', err);
      });
  }

  useEffect(() => {
    getCurrencyRate();
    setInterval(getCurrencyRate, 60000);
  }, []);

  return <div />;
}
