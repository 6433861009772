import { DialogContent, DialogTitle } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { useEffect, useState } from 'react';
import { editConsignmentRequest, getConsignmentByIDRequest } from '../../utils/api';
import appNotify from '../../utils/libs/appNotify';
import { useTranslation } from 'react-i18next';
import ConsignmentForm from './components/ConsignmentForm';
import PropTypes from 'prop-types';

function EditConsignmentDialog({ open, onClose, consignmentID = null }) {
  const [loading, setLoading] = useState(false);
  const [consignment, setConsignment] = useState(null);
  const { t } = useTranslation();
  function editConsignment(values) {
    setLoading(true);
    editConsignmentRequest(consignmentID, values)
      .then((res) => {
        appNotify.success('Consignment edited successfully');
        setLoading(false);
        onClose(res.consignment);
      })
      .catch((err) => {
        console.log('Err: ', err);
        appNotify.error(t('common.tryAgain'));
        setLoading(false);
      });
  }

  function getConsignment() {
    setLoading(true);
    getConsignmentByIDRequest(consignmentID)
      .then((res) => {
        const con = res.consignment;
        setConsignment({
          ...con,
          customer: { value: con.customer.id, label: `${con.customer.user.cellphone} ${con.customer.user.full_name}` },
          price_category: { value: con.price_category.id, label: con.price_category.name }
        });
        setLoading(false);
      })
      .catch(() => {
        appNotify.error('Error occurred in fetching consignment details');
        onClose();
        setLoading(false);
      });
  }

  useEffect(() => {
    if (consignmentID !== null) {
      getConsignment();
    }
  }, [consignmentID]);

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={() => onClose()}>
      <DialogTitle style={{ fontWeight: 'bold', fontSize: 20 }}>{t('goods.edit')}</DialogTitle>
      <DialogContent>
        {consignment !== null && (
          <ConsignmentForm isEdit loading={loading} initialValues={consignment} onSubmit={(values) => editConsignment(values)} />
        )}
      </DialogContent>
    </Dialog>
  );
}

EditConsignmentDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  consignmentID: PropTypes.any
};

export default EditConsignmentDialog;
