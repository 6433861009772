import * as Yup from 'yup';
import { Formik } from 'formik';
import { FormHelperText, Grid, InputLabel, OutlinedInput, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AnimateButton from '../../../ui-component/extended/AnimateButton';
import { useState } from 'react';
import ConsignmentsListForm from './ConsignmentsListForm';
import { NumericCustom } from '../../../ui-component';
import { useTranslation } from 'react-i18next';
import { AsyncSearchCustomersField, AsyncSearchPriceCategoriesField } from '../../../ui-component/AsyncFields';
import PropTypes from 'prop-types';

function ConsignmentForm({
  loading,
  onSubmit,
  isEdit = false,
  initialValues = {
    customer: '',
    name: '',
    warehouse: '',
    price_category: '',
    pkgs: '',
    shipping_charge: '',
    label: '',
    model_number: '',
    cbm: '',
    continue: true
  }
}) {
  const [consignmentsList, setConsignmentsList] = useState([]);
  const [consignmentsListError, setConsignmentsListError] = useState(false);

  const { t } = useTranslation();
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        customer: Yup.object().shape({
          value: Yup.string().max(255).required(t('common.errors.required.customer')),
          label: Yup.string().max(255).required(t('common.errors.required.customer'))
        }),
        name: Yup.string().max(255).required(t('common.errors.required.name')),
        price_category: Yup.object().shape({
          value: Yup.string().max(255).required(t('common.errors.required.price_category')),
          label: Yup.string().max(255).required(t('common.errors.required.price_category'))
        }),
        pkgs: Yup.string().max(255).required(t('common.errors.required.pkgs')),
        shipping_charge: Yup.string().max(255).required(t('common.errors.required.this')),
        label: Yup.string().max(255),
        model_number: Yup.string().max(255),
        cbm: Yup.string().max(255).required(t('common.errors.required.this'))
      })}
      onSubmit={async (values, { resetForm }) => {
        if (consignmentsList.length > 0 || isEdit) {
          console.log('Submitting');
          onSubmit(
            {
              ...values,
              customer: values.customer.value,
              // warehouse: values.warehouse.value,
              price_category: values.price_category.value,
              label: values.label || 'NON',
              goods: consignmentsList.map((i) => ({ ...i, good: i.good.value }))
            },
            () => {
              resetForm();
              setConsignmentsList([]);
            }
          );
          return;
        }
        setConsignmentsListError(true);
      }}
    >
      {({ errors, setFieldValue, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Grid container spacing={3} mb={3}>
            <Grid item xs={12} md={3} lg={3}>
              <Stack spacing={1}>
                <InputLabel htmlFor="bootstrap">{t('common.customer')}</InputLabel>
                <AsyncSearchCustomersField
                  value={values.customer ? values.customer : null}
                  onChange={(newValue) => {
                    if (newValue) {
                      setFieldValue('customer', newValue).then();
                      setConsignmentsList([]);
                      return;
                    }
                    setFieldValue('customer', '');
                    setConsignmentsList([]);
                  }}
                />
                {touched.customer && errors.customer && (
                  <FormHelperText error id="standard-weight-helper-text-email-login">
                    {errors.customer.label}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <Stack spacing={1}>
                <InputLabel htmlFor="bootstrap">{t('consignments.name')}</InputLabel>
                <OutlinedInput
                  id="email-login"
                  type="text"
                  value={values.name}
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="eg. Jeans"
                  fullWidth
                  multiline
                  error={Boolean(touched.name && errors.name)}
                />
                {touched.name && errors.name && (
                  <FormHelperText error id="standard-weight-helper-text-email-login">
                    {errors.name}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <Stack spacing={1}>
                <InputLabel htmlFor="bootstrap">{t('consignments.price_category')}</InputLabel>
                <AsyncSearchPriceCategoriesField
                  value={values.price_category ? values.price_category : null}
                  onChange={(newValue) => {
                    if (newValue) {
                      setFieldValue('price_category', newValue).then();
                      return;
                    }
                    setFieldValue('price_category', '').then();
                  }}
                />
                {touched.price_category && errors.price_category && (
                  <FormHelperText error id="standard-weight-helper-text-email-login">
                    {errors.price_category.label}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <Stack spacing={1}>
                <InputLabel htmlFor="bootstrap">{t('consignments.model_no')}</InputLabel>
                <OutlinedInput
                  id="email-login"
                  type="text"
                  value={values.model_number}
                  name="model_number"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={`${t('common.eg')}. 0012213`}
                  fullWidth
                  multiline
                  error={Boolean(touched.model_number && errors.model_number)}
                />
                {touched.model_number && errors.model_number && (
                  <FormHelperText error id="standard-weight-helper-text-email-login">
                    {errors.model_number}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={3} mb={3}>
            <Grid item xs={12} md={3} lg={3}>
              <Stack spacing={1}>
                <InputLabel htmlFor="bootstrap">{t('consignments.label')}</InputLabel>
                <OutlinedInput
                  id="email-login"
                  type="text"
                  value={values.label}
                  name="label"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={`${t('common.eg')}. 0012213`}
                  fullWidth
                  multiline
                  error={Boolean(touched.label && errors.label)}
                />
                {touched.label && errors.label && (
                  <FormHelperText error id="standard-weight-helper-text-email-login">
                    {errors.label}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <Stack spacing={1}>
                <InputLabel htmlFor="bootstrap">{t('consignments.pkgs')}</InputLabel>
                <OutlinedInput
                  id="email-login"
                  type="number"
                  value={values.pkgs}
                  name="pkgs"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={`${t('common.eg')}. 2`}
                  fullWidth
                  multiline
                  error={Boolean(touched.pkgs && errors.pkgs)}
                />
                {touched.pkgs && errors.pkgs && (
                  <FormHelperText error id="standard-weight-helper-text-email-login">
                    {errors.pkgs}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <Stack spacing={1}>
                <InputLabel htmlFor="bootstrap">{t('consignments.cbm')}</InputLabel>
                <OutlinedInput
                  id="email-login"
                  type="number"
                  value={values.cbm}
                  name="cbm"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={`${t('common.eg')}. 1`}
                  fullWidth
                  error={Boolean(touched.cbm && errors.cbm)}
                />
                {touched.cbm && errors.cbm && (
                  <FormHelperText error id="standard-weight-helper-text-email-login">
                    {errors.cbm}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <Stack spacing={1}>
                <InputLabel htmlFor="bootstrap">{t('consignments.shipping_cost')}</InputLabel>
                <OutlinedInput
                  id="email-login"
                  type="text"
                  value={values.shipping_charge}
                  name="shipping_charge"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={`${t('common.eg')} USD 4,050`}
                  inputComponent={NumericCustom}
                  fullWidth
                  error={Boolean(touched.shipping_charge && errors.shipping_charge)}
                />
                {touched.shipping_charge && errors.shipping_charge && (
                  <FormHelperText error id="standard-weight-helper-text-email-login">
                    {errors.shipping_charge}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
          </Grid>
          {!isEdit && (
            <ConsignmentsListForm
              customer={values.customer.value}
              consignmentsList={consignmentsList}
              setConsignmentsList={setConsignmentsList}
            />
          )}
          {consignmentsListError && consignmentsList.length === 0 && (
            <FormHelperText error id="standard-weight-helper-text-email-login">
              There should be least one consignment added
            </FormHelperText>
          )}
          <Grid mt={4} container spacing={3} direction="row" justifyContent="center" alignItems="center" mg={3}>
            <Grid item xs={3}>
              <AnimateButton>
                <LoadingButton
                  disableElevation
                  loading={loading}
                  disabled={loading}
                  fullWidth
                  size="large"
                  onClick={() => {
                    setFieldValue('continue', false).then(() => {});
                    handleSubmit();
                  }}
                  variant="contained"
                  color="primary"
                >
                  {t('common.submit')}
                </LoadingButton>
              </AnimateButton>
            </Grid>
            {!isEdit && (
              <Grid item xs={3}>
                <AnimateButton>
                  <LoadingButton
                    disableElevation
                    loading={loading}
                    disabled={loading}
                    fullWidth
                    size="large"
                    onClick={() => {
                      setFieldValue('continue', true).then((r) => {});
                      handleSubmit();
                    }}
                    variant="outlined"
                    color="primary"
                  >
                    {t('common.submit_and_cont')}
                  </LoadingButton>
                </AnimateButton>
              </Grid>
            )}
          </Grid>
        </form>
      )}
    </Formik>
  );
}

ConsignmentForm.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  isEdit: PropTypes.bool,
  initialValues: PropTypes.any
};

export default ConsignmentForm;
