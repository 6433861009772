import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import { Box, Collapse, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { IconChevronDown, IconChevronUp, IconEdit, IconTrash } from '@tabler/icons-react';
import { TableListSkeleton } from '../../../ui-component/Skeletons';
import PropTypes from 'prop-types';
import PermissionProvider from '../../users-roles-manager/components/PermissionHandler';
import { PERMISSIONS } from '../../../utils/libs/permissions';
import { CONSIGNMENT_STATUS } from '../../../utils/libs/constants';
import GoodsTable from '../../goods/components/GoodsTable';
import { getGoodConsignmentsByConsignmentID } from '../../../utils/api';
import appNotify from '../../../utils/libs/appNotify';
import { useTranslation } from 'react-i18next';
import { renderDateTime } from '../../../utils/libs';
import ConfirmDeleteConsignmentModal from './ConfirmDeleteConsignmentModal';
import EditConsignmentDialog from '../EditConsignmentDialog';

function ViewConsignedGoods({ consignmentID }) {
  const [goods, setGoods] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const { t } = useTranslation();
  const columns = [
    { id: 'created_at_', label: t('goods.created_at') },
    { id: 'name', label: t('goods.item') },
    { id: 'supplier_receipt_no', label: t('goods.supplier_receipt_no') },
    { id: 'pkgs', label: t('goods.pkgs') },
    { id: 'unit', label: t('goods.unit') },
    { id: 'quantity', label: t('goods.quantity') },
    { id: 'supplier_name', label: t('goods.supplier') }
  ];
  function getGoods() {
    getGoodConsignmentsByConsignmentID(consignmentID)
      .then((res) => {
        console.log('Results: ', res);
        setGoods(
          res.map((i) => ({
            ...i,
            supplier_receipt_no: i.good.supplier_receipt_no,
            name: i.good.name,
            supplier_name: i.good.supplier_name,
            created_at_: renderDateTime(i.good.created_at)
          }))
        );
        setPageLoading(false);
      })
      .catch((err) => {
        console.log('Err: ', err);
        appNotify.error('Failed to consigned goods');
      });
  }
  useEffect(() => {
    getGoods();
  }, []);
  return <GoodsTable showPagination={false} goods={goods} count={goods.length} columns={columns} pageLoading={pageLoading} />;
}

ViewConsignedGoods.propTypes = {
  consignmentID: PropTypes.number
};

function Row({ row, columns, onDelete, setConsignments }) {
  const [openConfirmDeleteConsignmentModal, setOpenConfirmDeleteConsignmentModal] = useState(false);
  const [openEditConsignmentModal, setOpenEditConsignmentModal] = useState(false);
  const [openGoods, setOpenGoods] = useState(false);
  return (
    <>
      <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
        {columns.map((column, idx) => {
          const value = row[column.id];
          if (column.id === 'actions') {
            return (
              <TableCell align="left" key={idx}>
                <IconButton aria-label="expand row" size="small" onClick={() => setOpenGoods((prevState) => !prevState)}>
                  {openGoods ? <IconChevronUp /> : <IconChevronDown />}
                </IconButton>
                {[CONSIGNMENT_STATUS.PENDING, CONSIGNMENT_STATUS.LOADED].includes(row.delivery_status) && (
                  <PermissionProvider permission={PERMISSIONS.CONSIGNMENTS_UPDATE}>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpenEditConsignmentModal(true)}>
                      <IconEdit />
                    </IconButton>
                  </PermissionProvider>
                )}
                {row.delivery_status === CONSIGNMENT_STATUS.PENDING && (
                  <PermissionProvider permission={PERMISSIONS.CONSIGNMENTS_DELETE}>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpenConfirmDeleteConsignmentModal(true)}>
                      <IconTrash />
                    </IconButton>
                  </PermissionProvider>
                )}
              </TableCell>
            );
          }
          return (
            <TableCell key={column.id} align={column.align}>
              {column.format && typeof value === 'number' ? column.format(value) : value}
            </TableCell>
          );
        })}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={openGoods} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>{openGoods && <ViewConsignedGoods consignmentID={row.id} />}</Box>
          </Collapse>
        </TableCell>
      </TableRow>
      {openConfirmDeleteConsignmentModal && (
        <ConfirmDeleteConsignmentModal
          onClose={() => setOpenConfirmDeleteConsignmentModal(false)}
          open={openConfirmDeleteConsignmentModal}
          consignment={row}
          onDelete={() => onDelete()}
        />
      )}
      {openEditConsignmentModal && (
        <EditConsignmentDialog
          onClose={(consignment = null) => {
            if (consignment) {
              // setConsign
              setConsignments((prevState) => prevState.map((c) => (c.id === row.id ? consignment : c)));
            }
            setOpenEditConsignmentModal(false);
          }}
          open={openEditConsignmentModal}
          consignmentID={row.id}
        />
      )}
    </>
  );
}

Row.propTypes = {
  row: PropTypes.any,
  columns: PropTypes.array,
  onDelete: PropTypes.func
};

function ConsignmentTable({
  onNextPage,
  pageLoading = true,
  next = null,
  columns = [],
  consignments = [],
  count = 0,
  showPaginator = true,
  onDelete,
  setConsignments
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    onNextPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableListSkeleton isLoading={pageLoading}>
        <TableContainer sx={{ maxHeight: 800 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {consignments.map((row, idx) => (
                <Row onDelete={onDelete} row={row} idx={idx} columns={columns} setConsignments={setConsignments} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TableListSkeleton>
      {showPaginator && (
        <TablePagination
          rowsPerPageOptions={[20]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          nextButton={{
            disabled: pageLoading || !next
          }}
          previouseButton={{
            disabled: pageLoading || page === 0
          }}
        />
      )}
    </Paper>
  );
}

ConsignmentTable.propTypes = {
  pageLoading: PropTypes.bool,
  next: PropTypes.any,
  columns: PropTypes.array,
  consignments: PropTypes.array,
  count: PropTypes.number,
  showPaginator: PropTypes.bool,
  onNextPage: PropTypes.func,
  onDelete: PropTypes.func,
  setConsignments: PropTypes.func
};

export default ConsignmentTable;
