import { listGoodsRequest, searchGoodsRequest } from '../../api';
import { useEffect, useState } from 'react';
import appNotify from '../appNotify';
import { useTranslation } from 'react-i18next';
import { debuggerConsole } from '../index';

export default function useGoods(init = false) {
  const [count, setCount] = useState(0);
  const [pageLoading, setPageLoading] = useState(true);
  const [goods, setGoods] = useState([]);
  const [next, setNext] = useState(null);
  const [goodOptions, setGoodOptions] = useState([]);
  const [goodOptionsLoading, setGoodOptionsLoading] = useState(false);
  const { t } = useTranslation();
  const [params, setParams] = useState({
    page: null,
    search: null,
    c: null
  });
  function listGoods() {
    setPageLoading(true);
    listGoodsRequest(params)
      .then((res) => {
        setGoods(res.results);
        setCount(res.count);
        setNext(res.next);
        setPageLoading(false);
      })
      .catch((err) => {
        debuggerConsole(err);
        appNotify.error(t('goods.failed_to_load'));
      });
  }

  function loadGoods() {
    setGoodOptionsLoading(true);
    searchGoodsRequest(params)
      .then((res) => {
        setGoodOptions(res.results);
        setGoodOptionsLoading(false);
      })
      .catch((err) => {
        debuggerConsole(err);
        setGoodOptions([]);
      });
  }

  useEffect(() => {
    if (init) {
      listGoods();
    } else if (params.c !== null) {
      loadGoods();
    }
  }, [params]);

  return { setGoods, setParams, listGoods, pageLoading, next, goods, count, goodOptionsLoading, goodOptions };
}
