import { CircularProgress, FormHelperText, Grid, IconButton, MenuItem, OutlinedInput, TextField, Tooltip } from '@mui/material';
import { Select } from 'antd';
import { UNITS } from '../../../utils/libs/constants';
import { Autocomplete, LoadingButton } from '@mui/lab';
import useGoods from '../../../utils/libs/hooks/use-goods';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { DeleteOutline } from '@mui/icons-material';
import AnimateButton from '../../../ui-component/extended/AnimateButton';
import { IconPlus } from '@tabler/icons-react';
import { useState } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { debuggerConsole } from '../../../utils/libs';

const initState = {
  good: { errors: false, value: {}, error_text: '' },
  unit: { errors: false, value: '', error_text: '' },
  quantity: { errors: false, value: '', error_text: '' },
  pkgs: { errors: false, value: '', error_text: '' }
};

function ConsignmentsListForm({ consignmentsList = [], setConsignmentsList, customer }) {
  const { goodOptions, setParams, goodOptionsLoading } = useGoods();
  const [item, setItem] = useState(initState);
  const { t } = useTranslation();
  const [add, setAdd] = useState(true);

  const schema = Yup.object().shape({
    good: Yup.object().shape({
      errors: Yup.boolean(),
      value: Yup.object().shape({
        value: Yup.string().required('Good is required'),
        label: Yup.string().required('Good is required')
      })
    }),
    unit: Yup.object().shape({
      errors: Yup.boolean(),
      value: Yup.string().required('Unit is required')
    }),
    pkgs: Yup.object().shape({
      errors: Yup.boolean(),
      value: Yup.string().required('Packages are required')
    }),
    quantity: Yup.object().shape({
      errors: Yup.boolean(),
      value: Yup.string().required('Quantity is required')
    })
  });

  const handleChangeItem = (e, field = null) => {
    setItem((prevState) => {
      let value = e;
      if (!field) {
        value = e.target.value;
        field = e.target.name;
      }
      return { ...prevState, [field]: { status: false, value } };
    });
  };

  // Validate
  const validate = async (item, setItem) => {
    try {
      await schema.validate(item, { abortEarly: false });
      return true;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          const [field, key] = error.path.split('.');
          setItem((prevState) => ({
            ...prevState,
            [field]: { ...prevState[field], errors: true, error_text: error.message.toString() }
          }));
        });
      }
      return false;
    }
  };

  const handleAddItem = () => {
    validate(item, setItem).then((valid) => {
      debuggerConsole('After validate: ', valid);
      if (valid) {
        setConsignmentsList((prevState) => [
          {
            good: item.good.value,
            quantity: item.quantity.value,
            pkgs: item.pkgs.value,
            unit: item.unit.value
          },
          ...prevState
        ]);
        setItem(initState);
        setAdd(false);
        // setListError(false);
      }
    });
    // debuggerConsole(shape);
  };

  const onDelete = (index) => {
    setConsignmentsList((prevState) => prevState.filter((_, sindex) => index !== sindex));
  };
  const columns = [
    { id: 'good', label: t('goods.item'), width: 300 },
    { id: 'pkgs', label: t('goods.pkgs'), width: 250 },
    { id: 'unit', label: t('goods.unit'), width: 250 },
    { id: 'quantity', label: t('goods.quantity'), width: 300 }
  ];
  return (
    <Grid container spacing={3} mb={3} mt={5} alignItems="center" justifyContent="center">
      <Grid item xs={12}>
        <TableContainer sx={{ maxHeight: 600 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id} align={column.align} style={{ width: column.width }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {consignmentsList
                .map((i) => ({ ...i, good: i.good.label }))
                .map((row, idx) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number' ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                    <TableCell>
                      <Tooltip title={t('common.remove')}>
                        <IconButton>
                          <DeleteOutline style={{ color: 'red' }} onClick={() => onDelete(idx)} />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              {add ? (
                <TableRow>
                  <TableCell width="35%">
                    <Autocomplete
                      id="good-async-select"
                      sx={{ width: '100%' }}
                      value={item.good.value.value !== null ? item.good.value.value : ''}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          handleChangeItem(newValue, 'good');
                          return;
                        }
                        handleChangeItem({}, 'good');
                      }}
                      onInputChange={(event, newInputValue) => {
                        if (customer) {
                          setParams({ customer, search: newInputValue, cbc: 'true' });
                        }
                      }}
                      filterOptions={(x) => x}
                      getOptionLabel={(option) => option.label}
                      options={goodOptions}
                      loading={goodOptionsLoading}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Search goods"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {goodOptionsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            )
                          }}
                        />
                      )}
                    />
                    {item.good.errors && (
                      <FormHelperText error id="standard-weight-helper-text-email-login">
                        {item.good.error_text}
                      </FormHelperText>
                    )}
                  </TableCell>
                  <TableCell width="10%">
                    <OutlinedInput
                      id="email-login"
                      type="number"
                      value={item.pkgs.value}
                      name="pkgs"
                      onChange={handleChangeItem}
                      // onBlur={handleBlur}
                      placeholder="eg. 2"
                      fullWidth
                      error={Boolean(item.pkgs.errors)}
                    />
                    {item.pkgs.errors && (
                      <FormHelperText error id="standard-weight-helper-text-email-login">
                        {item.pkgs.error_text}
                      </FormHelperText>
                    )}
                  </TableCell>
                  <TableCell width="10%">
                    <Select
                      labelId="demo-simple-select-disabled-label"
                      id="demo-simple-select-disabled"
                      value={item.unit.value}
                      placeholder="Select Unit"
                      onChange={(e) => handleChangeItem(e, 'unit')}
                      style={{
                        height: 50,
                        borderRadius: 30,
                        width: '100%'
                      }}
                      fullWidth
                      error={Boolean(item.unit.errors)}
                    >
                      {Object.entries(UNITS).map(([key, value]) => (
                        <MenuItem key={key} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                    {item.unit.errors && (
                      <FormHelperText error id="standard-weight-helper-text-email-login">
                        {item.unit.error_text}
                      </FormHelperText>
                    )}
                  </TableCell>
                  <TableCell width="10%">
                    <OutlinedInput
                      id="email-login"
                      type="number"
                      value={item.quantity.value}
                      name="quantity"
                      onChange={handleChangeItem}
                      placeholder={`${t('common.eg')}. 1000`}
                      fullWidth
                      // onBlur={handleBlur}
                      error={Boolean(item.quantity.errors)}
                    />
                    {item.quantity.errors && (
                      <FormHelperText error id="standard-weight-helper-text-email-login">
                        {item.quantity.error_text}
                      </FormHelperText>
                    )}
                  </TableCell>
                  <TableCell width="10%">
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <AnimateButton>
                          <LoadingButton
                            disableElevations
                            // loading={loading}
                            // disabled={loading}
                            fullWidth
                            size="large"
                            onClick={handleAddItem}
                            variant="contained"
                            color="primary"
                          >
                            {t('common.add')}
                          </LoadingButton>
                        </AnimateButton>
                      </Grid>
                      <Grid item xs={6}>
                        <AnimateButton>
                          <LoadingButton
                            disableElevations
                            fullWidth
                            size="large"
                            onClick={() => setAdd(false)}
                            variant="outlined"
                            color="error"
                          >
                            {t('common.cancel')}
                          </LoadingButton>
                        </AnimateButton>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell colSpan={5}>
                    <AnimateButton>
                      <LoadingButton
                        disableElevations
                        fullWidth
                        onClick={() => setAdd(true)}
                        size="large"
                        variant="text"
                        endIcon={<IconPlus />}
                      >
                        {t('common.add_another')}
                      </LoadingButton>
                    </AnimateButton>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );

  // return consignmentsList.map((val, idx) => (
  //   <Grid container spacing={3} mb={3} key={idx}>
  //     <Grid item xs={1}>
  //       <Grid container direction="row" justifyContent="center" alignItems="center">
  //         {val.index === 0 ? (
  //           <IconButton color="primary" aria-label="add an alarm" onClick={onAdd}>
  //             <AddCircleOutlineOutlinedIcon />
  //           </IconButton>
  //         ) : (
  //           <IconButton color="error" aria-label="add an alarm" onClick={() => onDelete(idx)}>
  //             <RemoveCircleOutlineOutlinedIcon />
  //           </IconButton>
  //         )}
  //       </Grid>
  //     </Grid>
  //     <Grid item xs={3}>
  //       <Stack spacing={1}>
  //         <InputLabel htmlFor="bootstrap">Good</InputLabel>
  //         <Autocomplete
  //           id="asynchronous-demo"
  //           sx={{ width: '100%' }}
  //           onChange={(event, newValue) => {
  //             if (newValue) {
  //               updateItem(val.index, 'good', newValue.value);
  //               return;
  //             }
  //             updateItem(val.index, 'good', '');
  //           }}
  //           onInputChange={(event, newInputValue) => {
  //             setGoodInputValue(newInputValue);
  //           }}
  //           filterOptions={(x) => x}
  //           getOptionLabel={(option) => option.label}
  //           options={goodOptions}
  //           loading={goodOptionsLoading}
  //           renderInput={(params) => (
  //             <TextField
  //               {...params}
  //               label="Search goods"
  //               InputProps={{
  //                 ...params.InputProps,
  //                 endAdornment: (
  //                   <>
  //                     {goodOptionsLoading ? <CircularProgress color="inherit" size={20} /> : null}
  //                     {params.InputProps.endAdornment}
  //                   </>
  //                 )
  //               }}
  //             />
  //           )}
  //         />
  //         {/* {touched.email && errors.email && ( */}
  //         {/*    <FormHelperText error id="standard-weight-helper-text-email-login"> */}
  //         {/*        {errors.email} */}
  //         {/*    </FormHelperText> */}
  //         {/* )} */}
  //       </Stack>
  //     </Grid>
  //     <Grid item xs={3}>
  //       <Stack spacing={1}>
  //         <InputLabel htmlFor="bootstrap">Unit</InputLabel>
  //         <Select
  //           labelId="demo-simple-select-disabled-label"
  //           id="demo-simple-select-disabled"
  //           // value={id_type}
  //           placeholder="Select Unit"
  //           onChange={(e) => {
  //             updateItem(val.index, 'unit', e);
  //           }}
  //           style={{
  //             height: 50,
  //             borderRadius: 30
  //           }}
  //           // error={Boolean(role_error)}
  //         >
  //           {Object.entries(UNITS).map(([key, value]) => (
  //             <MenuItem key={key} value={key}>
  //               {value}
  //             </MenuItem>
  //           ))}
  //         </Select>
  //         {/* {touched.amount && errors.amount && ( */}
  //         {/*  <FormHelperText error id="standard-weight-helper-text-email-login"> */}
  //         {/*    {errors.amount} */}
  //         {/*  </FormHelperText> */}
  //         {/* )} */}
  //       </Stack>
  //     </Grid>
  //     <Grid item xs={3}>
  //       <Stack spacing={1}>
  //         <InputLabel htmlFor="bootstrap">PCKGS</InputLabel>
  //         <OutlinedInput
  //           id="email-login"
  //           type="number"
  //           // value={values.email}
  //           name="first_name"
  //           onChange={(e) => updateItem(val.index, 'pkgs', e.target.value)}
  //           placeholder="eg. 2PKGS"
  //           fullWidth
  //           // error={Boolean(touched.email && errors.email)}
  //         />
  //         {/* {touched.email && errors.email && ( */}
  //         {/*    <FormHelperText error id="standard-weight-helper-text-email-login"> */}
  //         {/*        {errors.email} */}
  //         {/*    </FormHelperText> */}
  //         {/* )} */}
  //       </Stack>
  //     </Grid>
  //     <Grid item xs={2}>
  //       <Stack spacing={1}>
  //         <InputLabel htmlFor="bootstrap">Quantity</InputLabel>
  //         <OutlinedInput
  //           id="email-login"
  //           type="text"
  //           // value={values.email}
  //           name="first_name"
  //           onChange={(e) => updateItem(val.index, 'quantity', e.target.value)}
  //           placeholder="eg. 2PKGS"
  //           fullWidth
  //           // error={Boolean(touched.email && errors.email)}
  //         />
  //         {/* {touched.email && errors.email && ( */}
  //         {/*    <FormHelperText error id="standard-weight-helper-text-email-login"> */}
  //         {/*        {errors.email} */}
  //         {/*    </FormHelperText> */}
  //         {/* )} */}
  //       </Stack>
  //     </Grid>
  //   </Grid>
  // ));
}

export default ConsignmentsListForm;
