const Config = {
  // apiURL: 'http://127.0.0.1:4100/',
  // socketURL: 'ws://127.0.0.1:4100/',
  // mediaURL: 'http://localhost:5000/media/v1/',
  // hostURL: 'http://localhost:3006/',
  // apiURL: 'https://api.gnmcargo.com/',
  // socketURL: 'wss://api.gnmcargo.com/',
  // mediaURL: 'https://api.gnmcargo.com/v1/',
  hostURL: 'https://cmts.gnmcargo.com/',
  apiURL: 'https://cmts-dev.hardtechinternational.com/',
  socketURL: 'wss://cmts-dev.hardtechinternational.com/',
  mediaURL: 'https://cmts-dev.hardtechinternational.com/v1/',
  // hostURL: 'https://cmts.hardtechinternational.com/',
  languages: {
    en: 'English',
    zh: 'Chinese'
  },
  // @note Fallback language to use when the user's auto-determined language is not supported.
  fallbackLanguage: 'en'
};

export default Config;
