// theme constant
export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;

export const UNITS = {
  PIECES: 'Pieces',
  PRS: 'Prs',
  SET: 'Set',
  BALE: 'Bale',
  BOXES: 'Boxes',
  CARTONS: 'Cartons',
  DOZEN: 'Dozen',
  PACKAGE: 'Package',
  PALLET: 'Pallet'
};

export const SHIPPING_LINES = {
  APL: 'APL',
  PIL: 'PIL',
  CMA: 'CMA',
  MAERSK: 'MAERSK',
  COSCO: 'COSCO',
  HPL: 'HPL',
  ZIM: 'ZIM'
};

export const ID_TYPES = {
  NIDA: 'NIDA',
  VOTE: 'VOTE'
};

export const SMS_TYPES = {
  CUSTOMERS: 0,
  CUSTOM_CELLPHONE: 1,
  ALL_CUSTOMERS: 2,
  CONTAINER: 3
};

export const CONSIGNMENT_STATUS = {
  PENDING: 0,
  LOADED: 1,
  DELIVERED: 2,
  SHIPPED: 3,
  ARRIVED: 4,
  CANCELED: 5,
  AUTHORIZED_DISPATCH: 6,
  DISPATCHED: 7,
  DONE: 8
};

export const GOODS_STATUS = {
  PENDING: 0,
  STOCK: 1,
  CONSIGNED: 2
};

export const INVOICES_STATUS = {
  COMPLETED_PAYMENTS: 3,
  ONPROGRESS: 2,
  PENDING: 1,
  CANCELLED: 0
};

export const INVOICE_PAYMENTS_STATUS = {
  PENDING: 0,
  COMPLETED: 1
};

export const CONTAINER_STATUS = {
  BOOKED: 0,
  LOADED: 1,
  SHIPPED: 2,
  PORT: 3,
  ARRIVED: 4,
  CANCELED: 5
};

export const PAYMENT_TYPES = {
  CASH_PAYMENT: 0,
  ONLINE_PAYMENT: 1,
  MOBILE_PAYMENT: 2,
  BANK_PAYMENT: 3,
  E_CHECK: 4
};

export const SERVICE_PROVIDERS = {
  TIGO: 'TIGO',
  VODACOM: 'VODACOM',
  AIRTEL: 'AIRTEL',
  HALOTEL: 'HALOTEL'
};

export const CUSTOMER_STATUSES = {
  INACTIVE: 0,
  ACTIVE: 1
};

export const CATEGORIES_STATUSES = {
  INACTIVE: 0,
  ACTIVE: 1
};

export const DISPATCH_STATUSES = {
  PENDING: 0,
  DISPATCHED: 1
};

export const SMS_STATUS = {
  PENDING: 0,
  SENT: 1,
  RECEIVED: 2,
  FAILED: 3
};
